<template>
  <b-card body-class="p-0" class="sticky-top sub-header" :header-class="'pb-1 sub-header-border ' + env"
          :header-bg-variant="cardHeaderBGVariant" :header-text-variant="cardHeaderTextVariant">
    <template #header>
      <b-container fluid class="px-0">
        <slot></slot>
      </b-container>
    </template>
    <b-card-body v-if="false"/>
  </b-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SubHeader",
  data() {
    return {
      env: process.env.VUE_APP_ENV,
    }
  },
  computed: {
    ...mapGetters(["userThemeLight"]),
    cardHeaderBGVariant() {
      if(this.userThemeLight) { return 'grey-100' }
      return 'grey-700'
    },
    cardHeaderTextVariant() {
      if(this.userThemeLight) { return 'dark' }
      return 'light'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/SubHeader.scss';
</style>
