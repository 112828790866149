export function toastWarning(vm, message) {
    let options = {
        title: 'Warnung',
        variant: 'warning',
        toaster: 'b-toaster-bottom-right',
        headerClass: 'header-color',
        bodyClass: 'body-color',
        solid: true,
    }
    vm.$bvToast.toast(message, options)
}

export function toastDanger(vm, message) {
    let options = {
        title: 'Fehler',
        variant: 'danger',
        toaster: 'b-toaster-bottom-right',
        headerClass: 'header-color',
        bodyClass: 'body-color',
        solid: true,
    }
    vm.$bvToast.toast(message, options)
}

export function toastSuccess(vm, message) {
    let options = {
        title: 'Erfolgreich',
        variant: 'success',
        toaster: 'b-toaster-bottom-right',
        headerClass: 'header-color',
        bodyClass: 'body-color',
        solid: true,
    }
    vm.$bvToast.toast(message, options)
}

export function toastInfo(vm, message) {
    let options = {
        title: 'Information',
        variant: 'primary',
        toaster: 'b-toaster-bottom-right',
        headerClass: 'header-color',
        bodyClass: 'body-color',
        solid: true,
    }
    vm.$bvToast.toast(message, options)
}