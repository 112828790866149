import axios from 'axios';
import {toastDanger, toastSuccess} from './status';

export function getRequest(url, params = null, vm, errorMessage = 'Es ist ein Fehler aufgetreten!') {
    let config = {
        method: 'get', url: url, params: params
    };
    return axios(config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            toastDanger(vm, errorMessage);
            throw error;
        })
}

export function postRequest(url, data = null, vm, successMessage = 'Erfolgreich durchgeführt!', errorMessage = 'Es ist ein Fehler aufgetreten!') {
    let config = {
        method: 'post', url: url, data: data
    };
    return axios(config)
        .then((response) => {
            if(successMessage != null) {
                toastSuccess(vm, successMessage)
            }
            return response;
        })
        .catch((error) => {
            toastDanger(vm, errorMessage);
            throw error;
        })
}

export function putRequest(url, data = null, vm, successMessage = 'Erfolgreich durchgeführt!', errorMessage = 'Es ist ein Fehler aufgetreten!') {
    let config = {
        method: 'put', url: url, data: data
    };
    return axios(config)
        .then((response) => {
            if(successMessage != null) {
                toastSuccess(vm, successMessage)
            }
            return response;
        })
        .catch((error) => {
            toastDanger(vm, errorMessage);
            throw error;
        })
}
