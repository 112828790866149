<template>
  <b-modal id="imageModal" :title="title" size="xl" @ok="ok" @hidden="reset" @show="onShow">
    <b-form @submit.stop.prevent="">
      <b-form-row>
        <b-col cols="3">
          <label>Bezeichnung:</label>
          <b-form-input v-model="form.displayname" placeholder="Bezeichnung..." trim/>
        </b-col>
        <b-col cols="3">
          <label>Windowsversion:</label>
          <b-form-input v-model="form.os_version" placeholder="Windowsversion..." trim/>
        </b-col>
        <b-col cols="2">
          <label>Standardimage: <b-icon-question-circle-fill id="tt-default" :variant="iconVariant" class="header-icon"/></label>
          <b-form-checkbox class="ml-1 mt-1" v-model="form.default" :value="true" :unchecked-value="false"/>
          <b-tooltip target="tt-default" triggers="hover">Setzt dieses Image als neues Standardimage</b-tooltip>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col cols="6">
          <label>Beschreibung:</label>
          <b-form-input v-model="form.description" placeholder="Beschreibung..." trim/>
        </b-col>
        <b-col cols="2" v-if="method === 'edit'">
          <label>Inaktiv: <b-icon-question-circle-fill id="tt-active" :variant="iconVariant" class="header-icon"/></label>
          <b-form-checkbox class="ml-1 mt-1" v-model="form.inactive" :value="true" :unchecked-value="false"/>
          <b-tooltip target="tt-active" triggers="hover">Inaktives Image</b-tooltip>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col cols="3">
          <label>Imagetyp:</label>
          <b-form-select v-model="imageTypeSelect.selected" :options="imageTypeSelect.options">
            <template #first>
              <b-form-select-option :value="null" disabled>-- Imagetyp auswählen --</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="3">
          <label>Imageversion: <b-icon-question-circle-fill id="tt-version" :variant="iconVariant" class="header-icon"/></label>
          <b-form-input v-model="form.version" number type="number"/>
          <b-tooltip target="tt-version" triggers="hover">Versionsstand des Images</b-tooltip>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col cols="3">
          <label>Hintergrundfarbe:</label>
          <b-form-input v-model="form.color_background" type="color"/>
        </b-col>
        <b-col cols="3">
          <label>Textfarbe:</label>
          <b-form-select v-model="fontColorSelect.selected" :options="fontColorSelect.options"/>
        </b-col>
        <b-col cols="3">
          <label/>
          <div class="ml-2 mt-2">
            <h4><b-badge :style="badgeStyle">{{ form.displayname }}</b-badge></h4>
          </div>
        </b-col>
      </b-form-row>
    </b-form>
    <template #modal-footer="{ ok, cancel }">
      <b-button :variant="variantDanger" @click="cancel()">Abbrechen</b-button>
      <b-button :variant="variantSuccess" @click="ok">
        <b-spinner v-show="loadingOkButton" small/>
        OK
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import {getRequest, putRequest} from "@/modules/requests";

export default {
  name: "ImageModal",
  props: ['method', 'data'],
  data() {
    return {
      form: {
        id: null,
        displayname: '',
        description: '',
        version: 0,
        os_version: '',
        default: false,
        inactive: false,
        color_background: ''
      },
      imageTypeSelect: {
        selected: null,
        options: []
      },
      fontColorSelect: {
        selected: null,
        options: [
          {value: '#ffffff', text: 'Weiß'},
          {value: '#000000', text: 'Schwarz'}
        ]
      },
      loadingOkButton: false
    }
  },
  methods: {
    reset() {
      this.form = {
        id: null,
        displayname: '',
        description: '',
        version: 0,
        os_version: '',
        color_background: '',
        default: false,
        inactive: false
      }
      this.fontColorSelect.selected = null
      this.imageTypeSelect.selected = null
    },
    refresh() {
      this.$emit('refresh');
    },
    async ok() {
      switch (this.method) {
        case 'edit':
          await this.updateRow();
          break;
        case 'add':
          await this.addRow();
          break;
      }
      this.reset();
    },
    async onShow() {
      await this.getImageTypes()
      if (this.method === 'edit') {
        this.form.id = this.data.id
        this.form.displayname = this.data.displayname
        this.form.description = this.data.description
        this.form.version = this.data.version
        this.form.os_version = this.data.os_version
        this.form.default = this.data.default
        this.form.inactive = this.data.inactive
        this.form.color_background = this.data.color_background
        this.fontColorSelect.selected = this.data.color_font
        this.imageTypeSelect.selected = this.data.image_type_id
      }
      else {
        this.reset()
      }
    },
    async addRow() {
      this.loadingOkButton = true
      let data = {
        image_type_id: this.imageTypeSelect.selected,
        displayname: this.form.displayname,
        description: this.form.description,
        version: this.form.version,
        os_version: this.form.os_version,
        default: this.form.default,
        inactive: this.form.inactive,
        color_font: this.fontColorSelect.selected,
        color_background: this.form.color_background
      }

      await putRequest('images/fg/add', data, this, 'Neuer Eintrag wurde erfolgreich erstellt.', 'Neuer Eintrag konnte nicht erstellt werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('imageModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })
    },
    async updateRow() {
      let data = {
        id: this.form.id,
        image_type_id: this.imageTypeSelect.selected,
        displayname: this.form.displayname,
        description: this.form.description,
        version: this.form.version,
        os_version: this.form.os_version,
        default: this.form.default,
        inactive: this.form.inactive,
        color_font: this.fontColorSelect.selected,
        color_background: this.form.color_background
      }

      await putRequest('images/fg/update', data, this, 'Der Eintrag wurde erfolgreich aktualisiert.', 'Der Eintrag konnte nicht aktualisiert werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('imageModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })
    },
    async getImageTypes() {
      await getRequest('images/fg/image-types', null, this)
          .then((response) => {
            this.imageTypeSelect.options = []
            response.data.forEach(element => {
              this.imageTypeSelect.options.push({
                value: element.id,
                text: element.displayname
              })
            })
          })
    },
  },
  computed: {
    ...mapGetters(['userThemeLight']),
    ...mapGetters(['userColorAccessibility']),
    iconVariant() {
      if(this.userThemeLight) { return 'dark' }
      return 'white'
    },
    title() {
      if(this.method === 'edit') {
        return 'Image ändern'
      }
      return 'Image hinzufügen'
    },
    badgeStyle() {
      return {
        'color': this.fontColorSelect.selected,
        'background-color': this.form.color_background
      }
    },
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
