<template>
  <a @click="scrollTop" v-show="visible" class="bottom-right">
    <b-icon-arrow-up-square-fill scale="2.5"/>
  </a>
</template>

<script>
export default {
  name: "ScrollToTop",
  data() {
    return {
      visible: false
    }
  },
  methods: {
    scrollTop: function () {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId)
        }
        window.scroll(0, window.pageYOffset - 150)
      }, 20)
    },
    scrollListener: function () {
      this.visible = window.scrollY > 150
    }
  },
  mounted: function () {
    window.addEventListener('scroll', this.scrollListener)
  },
  beforeDestroy: function () {
    window.removeEventListener('scroll', this.scrollListener)
  }
}
</script>

<style lang="scss" scoped>
.bottom-right {
  position: fixed;
  bottom: 50px;
  right: 30px;
  cursor: pointer;
}
</style>
